<template>
  <div class="pl-16 pr-2">
    <v-card
      :min-height="windowH"
      class="mx-auto mt-3 mb-9 bgTransparente"
      max-width="calc(100% - 10px)"
      elevation="0"
    >
      <v-card-title class="ml-15 pt-8 pl-0 black--text"
        >ERP Gateways cadastrados</v-card-title
      >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="toogleRegisterGatewayDilog"
            v-bind="attrs"
            v-on="on"
            class="mt-13 mr-10"
            color="primary"
            fab
            dark
            :fixed="responsiveSize < 500"
            :absolute="responsiveSize >= 500"
            :bottom="responsiveSize < 500"
            :top="responsiveSize >= 500"
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar ERP Gateway</span>
      </v-tooltip>
      <v-data-table
        light
        :headers="headers"
        :items="gateway"
        :items-per-page="10"
        class="elevation-0 opacityTable mx-15"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at.split("T")[0] | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1 mr-3"
                @click="editGateway(item)"
                x-small
                color="primary"
                dark
                ><v-icon center x-small> edit </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Editar ERP Gateway</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1"
                @click="confirmDelGateway(item)"
                x-small
                color="red"
                dark
                ><v-icon center x-small> mdi-delete-forever </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Excluir ERP Gateway</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <RegisterGateway
      :updateGateway="updateGateway"
      :editGatewayData="editGatewayData"
      :toogleRegisterGatewayDilog="toogleRegisterGatewayDilog"
      :gatewayDialog="gatewayDialog"
    />
  </div>
</template>

<script>
import RegisterGateway from "./RegisterGateway.vue";
import MyFilters from "../../filters/genericFilters";
export default {
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  beforeMount() {
    this.getHeightPage();
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  mounted() {
    this.getGateway();
  },
  methods: {
    async getGatewayById(id) {
      let line;
      var obj = {
        url: "/erp-gateway/getById",
        query: { id: id },
        method: "post",
      };
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        line = response.data[0];
      });
      return line;
    },
    updateGateway(id) {
      this.getGateway();
      //console.log("update");
      // if (id) {
      //   this.getGatewayById(id).then((line) => {
      //     let index = this.gateway.findIndex((gateway) => gateway.id === id);
      //     this.gateway.splice(index, 1);
      //     this.gateway.splice(index, 0, line);
      //   });
      // }
    },
    editGateway(data) {
      this.editGatewayData = { ...data };
      this.gatewayDialog = true;
    },
    confirmDelGateway(data) {
      let obj = {
        message: `Você deseja excluir o ERP Gateway <b>${data.name}</b>?`,
        title: "Excluir ERP Gateway",
        callback: (_) => {
          this.delGateway(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delGateway(data) {
      let obj = {
        url: "/api/erp-gateway/delete",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let index = this.gateway.findIndex(
          (provider) => provider.id === data.id
        );
        this.gateway.splice(index, 1);
        let opts = {
          message: `ERP Gateway <b>${data.name}</b> removido com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    toogleRegisterGatewayDilog() {
      this.editGatewayData = null;
      this.gatewayDialog = !this.gatewayDialog;
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
    getGateway() {
      var obj = {
        url: "/api/erp-gateway/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.gateway = [...response.data];
      });
    },
  },
  data() {
    return {
      windowH: "580",
      editGatewayData: null,
      gatewayDialog: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "Endpoint", value: "endpoint", sortable: false },
        { text: "Data de criação", value: "created_at", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      gateway: [],
    };
  },
  components: {
    RegisterGateway,
  },
};
</script>

<style>
</style>