<template>
  <v-row justify="center">
    <v-dialog light v-model="gatewayDialog" persistent max-width="560px">
      <v-card>
        <v-card-title class="headline px-2">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0">
              {{ formData.id ? "Editar ERP Gateway" : "Novo ERP Gateway" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn
              @click="toogleRegisterGatewayDilog"
              class="mt-2"
              color="red"
              fab
              icon
              dark
              x-small
              absolute
              top
              right
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="erpGatewayForm" v-model="valid">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="formData.name"
                    label="Nome do serviço"
                    :rules="$store.state.formRules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="formData.endpoint"
                    label="Endpoint"
                    :rules="$store.state.formRules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions
          class="px-0 mx-3"
          style="border-top: 1px solid rgb(220, 220, 220)"
        >
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="toogleRegisterGatewayDilog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="saveGateway"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const resetGatewayForm = {
  name: "",
  endpoint: "",
  company_id: "",
};
export default {
  methods: {
    saveGateway() {
      //console.log("servicsave");
      if (!this.$refs.erpGatewayForm.validate()) {
        return;
      }

      let url = this.formData.id
        ? "/api/erp-gateway/update"
        : "/api/erp-gateway/create";
      //this.formData.company_id = this.selectedCompany.id;
      var obj = {
        url: url,
        query: this.formData,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response)
        let opts = {
          message: `ERP Gateway ${
            this.formData.id ? "atualizado" : "criado"
          } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.updateGateway();
        this.toogleRegisterGatewayDilog();
      });
    },
  },
  watch: {
    gatewayDialog(v) {
      if (!v) {
        this.$refs.erpGatewayForm.resetValidation();
        this.formData = { ...resetGatewayForm };
      } else {
        if (this.editGatewayData) {
          this.formData = this.editGatewayData;
        }
      }
    },
  },
  mounted() {},
  props: {
    updateGateway: Function,
    editGatewayData: Object,
    toogleRegisterGatewayDilog: Function,
    gatewayDialog: Boolean,
  },
  data() {
    return {
      companies: [],
      selectedCompany: null,
      valid: true,
      formData: { ...resetGatewayForm },
    };
  },
};
</script>

<style>
</style>